import ApplicationLogo from '@/Components/ApplicationLogo';
import { Link } from '@inertiajs/react'
import FlashMessage from '@/Components/FlashMessages';

export default function Guest({ children }) {
    return (
        <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
            <div>
                <Link href="/">
                    <ApplicationLogo/>
                </Link>
            </div>

            <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-base-200 shadow-md overflow-hidden sm:rounded-lg">
                <FlashMessage/>
                {children}
            </div>

            <div className='flex gap-4 py-4'>
                <a href={route('terms')} className="link link-hover">Terms of Service</a> 
                <a href={route('privacy')} className="link link-hover">Privacy policy</a> 
            </div>
        </div>
    );
}
